<template>
  <div class="file-search">
    <div class="line-one">欢迎使用</div>
    <div class="line-two">鸿山寺牌位号查询</div>
    <div class="input">
      <el-input placeholder="请输入手机号" v-model="phone"></el-input>
    </div>
    <div>
      <el-button type="danger" class="input" @click="search">查询</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileSearch",
  data() {
    return {
      phone: '',
    }
  },
  methods: {
    async search() {
      this.$router.push({name: 'FileSearchResult', params: {phone: this.phone}})
    }
  }
}
</script>

<style scoped>
.file-search {
  background-image: url("../assets/bg.png");
  background-size: 100% 100%;
  height: 100%;
  width: 100%;

  padding-top: 122px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.line-one {
  font-size: 22px;
  margin-bottom: 20px;
}

.line-two {
  font-size: 26px;
  margin-bottom: 56px;
}

.input {
  margin-bottom: 25px;
  width: 320px;
}
</style>